<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="种质名称" prop="germplasmName">
        <el-input
          v-model="queryParams.germplasmName"
          placeholder="请输入种质名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="主要用途" prop="purpose">
        <el-input
          v-model="queryParams.purpose"
          placeholder="请输入主要用途"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="保存单位" prop="saveUnit">
        <el-input
          v-model="queryParams.saveUnit"
          placeholder="请输入保存单位"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="保存库" prop="saveDataset">
        <el-input
          v-model="queryParams.saveDataset"
          placeholder="请输入保存库"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="保存地点" prop="savePlace">
        <el-input
          v-model="queryParams.savePlace"
          placeholder="请输入保存地点"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="入库年份" prop="year">
        <el-input
          v-model="queryParams.year"
          placeholder="请输入入库年份"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="保存性质" prop="saveNature">
        <el-input
          v-model="queryParams.saveNature"
          placeholder="请输入保存性质"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
        >导出</el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="resourcesList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="种质编号" align="center" prop="identifier" width="160px"/>
      <el-table-column label="作(植)物类别" align="center" prop="type" />
      <el-table-column label="作(植)物名称" align="center" prop="name" />
      <el-table-column label="种质名称" align="center" prop="germplasmName" />
      <el-table-column label="主要特征" align="center" prop="feature" />
      <el-table-column label="主要用途" align="center" prop="purpose" />
      <el-table-column label="资源描述" align="center" prop="describe·" />
      <el-table-column label="保存设施" align="center" prop="saveFacilities" />
      <el-table-column label="种质类型" align="center" prop="germplasmType" width="60px"/>
      <el-table-column label="保存数量" align="center" prop="num" />
      <el-table-column label="计量单位" align="center" prop="measurementUnit" width="60px"/>
      <el-table-column label="保存单位" align="center" prop="saveUnit" />
      <el-table-column label="保存库" align="center" prop="saveDataset" />
      <el-table-column label="保存地点" align="center" prop="savePlace" />
      <el-table-column label="入库年份" align="center" prop="year" width="60px"/>
      <el-table-column label="保存性质" align="center" prop="saveNature" />
      <el-table-column label="是否共享" align="center" prop="share" width="60px">
        <template slot-scope="scope">
          <p>
            <span v-if="scope.row.share === 0">否</span>
            <span v-else>是</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="共享方式" align="center" prop="shareMode" />
      <el-table-column label="种质图片" align="center" prop="img">
        <template slot-scope="scope">
          <img v-if="scope.row.img!=='' && scope.row.img!==null" :src="scope.row.img"  style="height: 80px" width="88px">
          <img v-else src="~@/assets/img/resources_img.jpg" style="height: 80px" width="88px">
        </template>
      </el-table-column>
      <el-table-column label="项目归口" align="center" prop="projectAlley" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

<!--    <pagination-->
<!--      v-show="total>0"-->
<!--      :total="total"-->
<!--      :page.sync="queryParams.pageNum"-->
<!--      :limit.sync="queryParams.pageSize"-->
<!--      @pagination="getList"-->
<!--    />-->

    <!-- 添加或修改对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="60%" append-to-body>
      <resource-data-save :update="true" :id="selectId" ref="ResourceDataSave" @updateSuccess="handleUpdateSuccess"></resource-data-save>
    </el-dialog>
  </div>
</template>

<script>
// import { listResources, getResources, delResources, addResources, updateResources, exportResources } from "@/api/system/resources";

import ResourceDataSave from './resources-data-save'

export default {
  // name: "Resources",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 【请填写功能名称】表格数据
      resourcesList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        identifier: undefined,
        type: undefined,
        name: undefined,
        germplasmName: undefined,
        feature: undefined,
        purpose: undefined,
        describe: undefined,
        saveFacilities: undefined,
        germplasmType: undefined,
        num: undefined,
        measurementUnit: undefined,
        saveUnit: undefined,
        saveDataset: undefined,
        savePlace: undefined,
        year: undefined,
        saveNature: undefined,
        share: undefined,
        shareMode: undefined,
        img:undefined,
        projectAlley: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      },
      selectId: '',
    };
  },
  components:{
    ResourceDataSave,
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询资源列表 */
    getList() {
      this.loading = true;
      this.$http({
        url: this.$http.adornUrl('/resources/list'),
        method: 'get',
      }).then(response => {
          this.resourcesList = response.data.data;
          console.log(this.resourcesList)
          // this.total = response.total;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        identifier: undefined,
        type: undefined,
        name: undefined,
        germplasmName: undefined,
        feature: undefined,
        purpose: undefined,
        describe: undefined,
        saveFacilities: undefined,
        germplasmType: undefined,
        num: undefined,
        measurementUnit: undefined,
        saveUnit: undefined,
        saveDataset: undefined,
        savePlace: undefined,
        year: undefined,
        saveNature: undefined,
        share: undefined,
        shareMode: undefined,
        img: undefined,
        projectAlley: undefined
      };
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.selectId = row.id || this.ids;
      this.open = true;
      this.title = "修改";
      this.$nextTick(() => {
        this.$refs.ResourceDataSave.init()
      })
    },
    handleUpdateSuccess(){
      this.open=false;
      this.getList();
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id !== undefined) {
            updateResources(this.form).then(response => {
              if (response.code === 200) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              }
            });
          } else {
            addResources(this.form).then(response => {
              if (response.code === 200) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('是否确认删除资源项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
         return this.$http({
          url: this.$http.adornUrl('/resources/'+ids),
          method: 'delete',
        })
        }).then(resp=>{
          if(resp.data.code === 0){
            this.$message.success("删除成功！");
            this.getList();
          }else{

            this.$message.success("删除失败，请重试！");
          }
      }).catch(function() {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有【请填写功能名称】数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportResources(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
    }
  }
};
</script>
